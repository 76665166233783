import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, about2, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-1px">
          <CFImage
            src={mobileAbout}
            w="100%"
            alt="Dami Japanese Restaurant About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" maxWidth="1400px" mt="-1px">
          <CFImage src={about} w="100%" alt="About" />
          <CFImage src={about2} w="100%" alt="About" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
