import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promotions, mobilePromotion2 } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-1px">
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" />
          <CFImage
            src={mobilePromotion2}
            w="100%"
            alt="Promotions 2"
            mt="-1px"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto" w="100%">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
